<script>
export default {
  name: "no-data",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "اطلاعاتی یافت نشد.",
    },
    showImg: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <div v-if="show" class="no-data flex-center flex-column h-100">
    <h4 class="text-center w-100 text-muted">
      {{ message }}
    </h4>
    <img v-if="showImg" src="/img/no-data.png" class="no-result-image" alt="no result" />
  </div>
</template>

<style scoped>
.no-result-image {
  max-width: 20rem;
  width: 50vw;
}
</style>
