<template>
  <div class="tab-body-card" style="height: calc(100vh - 10vh)">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow>
      <CCol md="4" class="specialist-list-section">
        <CRow class="mb-4 gap-3">
          <CCol md="12">
            <CInput
              v-model="searchQuery"
              placeholder="جستجو بر اساس نام یا تخصص"
            />
          </CCol>
        </CRow>
        <CRow class="gap-10 specialist-list">
          <CCol
            md="12"
            v-for="(specialist, index) in specialistsList"
            :key="index"
          >
            <SpecialistCard
              :specialist="specialist"
              @highlightOnMap="highlightOnMap"
              @viewDetails="viewSpecialistDetails"
              @request="CooperationRequest"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol md="8">
        <div style="height: 87vh; width: 100%; position: relative">
          <div id="map"></div>
        </div>
      </CCol>
    </CRow>
    <!-- Specialist Details Modal -->
    <VueModal
      :title="'جزئیات ' + currentSpecialist.fullName"
      v-model="specialistModalState"
      wrapper-class="animate__animated animate__faster"
      modal-class="fullscreen-modal"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
    >
    </VueModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SpecialistCard from "./SpecialistCard.vue";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  name: "SpecialistsTab",
  components: {
    SpecialistCard,
  },
  data() {
    return {
      searchQuery: "",
      specialistsList: [],
      map: null,
      specialistModalState: false,
      loading: false,
      loadingText: "درحال دریافت اطلاعات ...",
      currentSpecialist: {},
    };
  },
  computed: {},
  methods: {
    ...mapActions("farmerColleagues", ["GetAllAdvicer", "SendRequest"]),

    async fetchData() {
      let result = await this.GetAllAdvicer({
        searchText: this.searchQuery,
      });
      if (result.succeeded) {
        result.data.forEach((element, index) => {
          this.specialistsList.push({
            id: element.advicerId,
            fullName: `${element.name} ${element.lastName}`,
            phoneNumber: element.phone,
            experience: element.workExperience,
            description:
              element.expertises.length > 0
                ? element.expertises.join("/")
                : "ثبت نشده",
            location: element.location ?? null,
            registerDateTime: element.registerDateTime,
          });
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
    },

    showAllSpecialistsOnMap() {
      this.specialistsList.forEach((item) => {
        if (item.location) {
          let lng = JSON.parse(item.location)[0];
          let lat = JSON.parse(item.location)[1];
          new mapboxgl.Marker()
            .setLngLat({ lng, lat })
            .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(item.fullName))
            .addTo(this.map);
        }
      });
    },

    highlightOnMap(specialist) {
      const [lng, lat] = JSON.parse(specialist.location);
      this.map.flyTo({ center: [lng, lat], zoom: 10 });
    },
    CooperationRequest(specialist) {
      this.$confirm({
        message: `درخواست همکاری برای  ${specialist.fullName} ارسال شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال ارسال درخواست";
            let result = await this.SendRequest({
              userId: specialist.id,
              userType: "Advicer",
            });
            this.loading = false;
            if (result.succeeded) {
              this.$notify({
                title: "انجام شد",
                text: "درخواست همکاری برای متخصص ارسال شد <br /><br />",
                type: "success",
              });
              this.fetchData();
            } else {
              this.$notify({
                title: "خطا",
                text: `${result.message} <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
    viewSpecialistDetails(specialist) {
      this.currentSpecialist = specialist;
      this.specialistModalState = true;
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";

    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }

    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      center: [53.126, 33.593],
      zoom: 5,
    });

    this.map.on("load", () => {
      this.fetchData().then(() => {
        this.showAllSpecialistsOnMap();
      });
      this.map.resize();
    });
  },
};
</script>  
  
<style scoped>
.map-viewer {
  position: relative;
  height: 77vh;
  height: calc(100vh - 25vh);
  overflow-y: auto;
}
.specialist-list {
  height: calc(100vh - 25vh);
  overflow-y: auto;
  padding-bottom: 10vh;
}
</style>
