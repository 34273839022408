<script>

import MainBodyHeader from "./mainHeader.vue";
import CmpAds from "./cmpAds.vue";

export default {
  components: {CmpAds, MainBodyHeader},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    ads: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  }
}
</script>

<template>
  <div class="h-100 position-relative">
    <div>
      <MainBodyHeader :title="title">
        <slot name="header"></slot>
      </MainBodyHeader>
      <div class="pb-4 h-100">
        <CRow v-if="ads">
          <CCol col="3" class="w-100">
            <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-400ms"></cmp-ads>
          </CCol>
          <CCol col="3" class="w-100">
            <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-550ms"></cmp-ads>
          </CCol>
          <CCol col="3" class="w-100">
            <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-700ms"></cmp-ads>
          </CCol>
          <CCol col="3" class="w-100">
            <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-850ms"></cmp-ads>
          </CCol>
        </CRow>
        <hr class="my-3 mx-3" v-if="ads"/>
        <CRow class="h-100">
          <CCol md="12" class="h-100">
            <vue-element-loading
              :active="loading"
              spinner="bar-fade-scale"
              color="var(--secondary)"
            />
            <slot></slot>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
