<template>
  <div>
    <body-with-header ads title="مدیریت همکاری و متخصصین">
      <CRow class="h-100">
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CCol md="12">
          <CTabs fade variant="tabs">
            <!-- Tab 2: متخصصان (Specialists) -->
            <CTab active>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>متخصصان</span>
                  <font-awesome-icon icon="user-md" />
                </div>
              </template>
              <SpecialistsTab />
            </CTab>

            <!-- Tab 3: کارمندان مزرعه (Farm Employees) -->
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>کارمندان مزرعه</span>
                  <font-awesome-icon icon="users" />
                </div>
              </template>
              <FarmEmployeesTab />
            </CTab>
            <!-- Tab 1: تاریخچه درخواست‌ها (Request History) -->
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>تاریخچه درخواست‌ها</span>
                  <font-awesome-icon icon="history" />
                </div>
              </template>
              <RequestHistoryTab />
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </body-with-header>
  </div>
</template>
 
<script>
import RequestHistoryTab from "./requests/RequestHistoryTab.vue";
import SpecialistsTab from "./specialists/SpecialistsTab.vue";
import FarmEmployeesTab from "./employees/FarmEmployeesTab.vue";
import BodyWithHeader from "../../components/bodyWithHeader.vue";

export default {
  components: {
    BodyWithHeader,
    RequestHistoryTab,
    SpecialistsTab,
    FarmEmployeesTab,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال بارگذاری...",
    };
  },
};
</script>
