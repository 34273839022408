<template>
  <div class="main-card gap-20 d-flex flex-column">
    <CRow class="bodyItem">
      <CCol col="8" class="p-0">
        <CCol col="12" class="bodyItemValue y-center-g-5">
          <img
            :src="userImage"
            @error="() => (userImage = 'https://picsum.photos/200')"
            class="specialistImage"
            alt="Specialist Image"
          />
          <h4>{{ request.fullName }}</h4>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>شماره همراه :</span>
          <span>{{ request.phone }}</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>نوع کاربری :</span>
          <span>{{ request.userType }}</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>تاریخ درخواست :</span>
          <span>{{ request.rquestDateTime }}</span>
        </CCol>
        <CCol col="12" class="bodyItemValue">
          <span>وضعیت درخواست :</span>
          <span :class="getStatusClass(request.status)">
            {{ getStatusLabel(request.status) }}
          </span>
        </CCol>
      </CCol>
      <CCol col="4 p-0">
        <CButton
          v-if="request.status == 0"
          class="requestItemBtn flex-grow-1"
          color="warning"
          @click="cancelCooperationRequest(request)"
          style="padding: 5px 12px; width: 100%; margin-top: 10px"
        >
          لغو درخواست
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>


<style scoped>
.bodyItemValue {
  width: 100%;
}

.bodyItemValue h4 {
  font-size: 1rem;
  padding-right: 4px;
}

.specialistImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.btn-view {
  background-color: var(--primary-light);
  color: #fff;
}

.specialistItemBtn {
  width: 100%;
  margin-top: 4px;
  padding: 6px 0px;
}
</style>
<script>
export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userImage: "https://picsum.photos/200",
    };
  },
  computed: {
    requestFields() {
      return [
        { label: "شماره همراه", value: this.request.phone },
        { label: "نوع کاربری", value: this.request.userType },
        { label: "تاریخ درخواست", value: this.request.rquestDateTime },
        {
          label: "وضعیت",
          value: this.getStatusLabel(this.request.status),
          slot: "status",
        }, // Use 'status' slot for status field
      ];
    },
  },
  methods: {
    cancelCooperationRequest(request) {
      this.$emit("cancelCooperation", request);
    },

    getStatusLabel(status) {
      const statusOptions = [
        { value: 0, label: "در حال بررسی" },
        { value: 1, label: "رد شده" },
        { value: 2, label: "تایید شده" },
      ];
      const statusOption = statusOptions.find(
        (option) => option.value === status
      );
      return statusOption ? statusOption.label : "";
    },
    getStatusClass(status) {
      return status === 2
        ? "text-success"
        : status === 1
        ? "text-danger"
        : "text-warning";
    },
  },
};
</script>

<style scoped>
/* Custom styles for RequestCard */
</style>
